import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

interface LogoProps extends SvgProps {
  isDark: boolean;
}

const Logo: React.FC<LogoProps> = ({ isDark, ...props }) => {
  const logoImage = isDark ? (
    <img src="/images/logo-with-text.png" width="100" height="50" />
  ) : (
    <img src="/images/logo-with-text.png" width="100" height="50" />
  );
  return logoImage;
};

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark);
