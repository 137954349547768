import { FooterLinkType } from '@kaiso/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  // {
  //   label: t('About'),
  //   items: [
  //     {
  //       label: t('Contact'),
  //       href: 'https://docs.kaiso.finance/contact-us',
  //       isHighlighted: true,
  //     },
  //     {
  //       label: t('Brand'),
  //       href: 'https://docs.kaiso.finance/brand',
  //     },
  //     {
  //       label: t('Blog'),
  //       href: 'https://medium.com/kaiso',
  //     },
  //     {
  //       label: t('KAISO token'),
  //       href: 'https://docs.kaiso.finance/tokenomics/kaiso',
  //     },
  //   ],
  // },
  {
    label: t('Products'),
    items: [
      {
        label: t('MultiSender'),
        href: '/multisender',
        isHighlighted: false,
        isInternalLink: true,
      },
    ],
  },
  // {
  //   label: t('Help'),
  //   items: [
  //     {
  //       label: t('FAQ'),
  //       href: '/faq',
  //     },
  //   ],
  // },
  // {
  //   label: t('Developers'),
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/kaiso-finance',
  //     },
  //     {
  //       label: t('Documentation'),
  //       href: 'https://docs.kaiso.finance',
  //     },
  //   ],
  // },
]
