import { CHAIN_ID } from './networks'
import { serializeTokens } from './tokens'
import { SerializedPoolConfig } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  // {
  //   sousId: 104,
  //   stakingToken: serializedTokens.astariant,
  //   earningToken: serializedTokens.kaiso,
  //   contractAddress: {
  //     592: '',
  //     81: '0xb343a30c37f6C3454D2Daa336dc134cb0A985828',
  //   },
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '0.04',
  //   minimumStaking: '0 blocks',
  //   unboundingPeriod: '0 blocks',
  // },
  {
    sousId: 103,
    stakingToken: serializedTokens.astariant,
    earningToken: serializedTokens.kaiso,
    contractAddress: {
      592: '',
      81: '0xe88e3becB87a0212822f355d5E6C8002AA978399',
    },
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.025',
    minimumStaking: '50 blocks',
    unboundingPeriod: '0 blocks',
  },
  {
    sousId: 102,
    stakingToken: serializedTokens.astariant,
    earningToken: serializedTokens.kaiso,
    contractAddress: {
      592: '',
      81: '0x8d1C0dF2Ba912435C9B7E19aefA2178A155c84E1',
    },
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.015',
    minimumStaking: '0 blocks',
    unboundingPeriod: '200 blocks',
  },
  {
    sousId: 101,
    stakingToken: serializedTokens.degent,
    earningToken: serializedTokens.ad,
    contractAddress: {
      592: '',
      81: '0x7199102b711Dd5cB58e37B9f4B3c8Ea9E515F4d7',
    },
    harvest: true,
    sortOrder: 998,
    tokenPerBlock: '0.01',
    minimumStaking: '50 blocks',
    unboundingPeriod: '50 blocks',
  },
  {
    sousId: 100,
    stakingToken: serializedTokens.astariant,
    earningToken: serializedTokens.kaiso,
    contractAddress: {
      592: '',
      81: '0x8ccDc1d5c6C40Bee56F179D9A72a03Eb289010e3',
    },
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.01',
    minimumStaking: '50 blocks',
    unboundingPeriod: '50 blocks',
  },
].filter((p) => !!p.contractAddress[CHAIN_ID])

export default pools
