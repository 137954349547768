import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, MediumIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
  // {
  //   label: "About",
  //   items: [
  //     {
  //       label: "Contact",
  //       href: "https://docs.kaiso.finance/contact-us",
  //     },
  //     {
  //       label: "Blog",
  //       href: "https://pancakeswap.medium.com/",
  //     },
  //     {
  //       label: "Community",
  //       href: "https://docs.kaiso.finance/contact-us/telegram",
  //     },
  //     {
  //       label: "CAKE",
  //       href: "https://docs.kaiso.finance/tokenomics/cake",
  //     },
  //     {
  //       label: "—",
  //     },
  //     {
  //       label: "Online Store",
  //       href: "https://pancakeswap.creator-spring.com/",
  //       isHighlighted: true,
  //     },
  //   ],
  // },
  {
    label: "Products",
    items: [
      {
        label: "Kaiso MultiSender",
        href: "https://kaiso.finance/multisender",
      },
      {
        label: "Blog",
        href: "https://pancakeswap.medium.com/",
      },
      {
        label: "Community",
        href: "https://docs.kaiso.finance/contact-us/telegram",
      },
      {
        label: "CAKE",
        href: "https://docs.kaiso.finance/tokenomics/cake",
      },
      {
        label: "—",
      },
      {
        label: "Online Store",
        href: "https://pancakeswap.creator-spring.com/",
        isHighlighted: true,
      },
    ],
  },
  {
    label: "Help",
    items: [
      {
        label: "Customer",
        href: "Support https://docs.kaiso.finance/contact-us/customer-support",
      },
      {
        label: "Troubleshooting",
        href: "https://docs.kaiso.finance/help/troubleshooting",
      },
      {
        label: "Guides",
        href: "https://docs.kaiso.finance/get-started",
      },
    ],
  },
  {
    label: "Developers",
    items: [
      {
        label: "Github",
        href: "https://github.com/pancakeswap",
      },
      {
        label: "Documentation",
        href: "https://docs.kaiso.finance",
      },
      {
        label: "Bug Bounty",
        href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty",
      },
      {
        label: "Audits",
        href: "https://docs.kaiso.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited",
      },
      {
        label: "Careers",
        href: "https://docs.kaiso.finance/hiring/become-a-chef",
      },
    ],
  },
];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/KaisoFinance",
  },

  {
    label: "Github",
    icon: GithubIcon,
    href: "https://github.com/kaiso-finance/",
  },
  {
    label: "Discord",
    icon: DiscordIcon,
    href: "https://discord.gg/7hjR5rdKwk",
  },
  // {
  //   label: "Medium",
  //   icon: MediumIcon,
  //   href: "https://medium.com/@kaiso.io",
  // },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
