import { ChainId, Token } from '@kaiso/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = defineTokens({
  kaiso: new Token(
    MAINNET,
    '0x7C24C4b7856a432f3b8e2653d6d651e4fA1189ff',
    18,
    'KAISO',
    'Kaiso Token',
    'https://kaiso.finance',
  ),
  astariant: new Token(
    MAINNET,
    '0xF22D2FEB149Fbf61915774a5bD2897c2157337e1',
    0,
    'ASTARIANT',
    'Astarians Plus Test',
    'https://astariansplus.com',
  ),
  ad: new Token(
    MAINNET,
    '0xF87Bb037d27C40104fa556b31ceA1a698880Be7E',
    18,
    'AD',
    'Astar Degens Token - Test',
    'https://astardegens.com',
  ),
  degent: new Token(
    MAINNET,
    '0xA190C3654bE9b14A26473eCa2Ceb4c8EE6F1e85d',
    0,
    'DEGENT',
    'Astar Degens Test',
    'https://astardegens.com',
  ),
  aaa: new Token(MAINNET, '0x22851240c98cc4F3e5e0b729965100A55Aa1AC57', 0, 'AAA', 'AAA NFT', 'https://aaa.org'),
  stk: new Token(MAINNET, '0x19547eF6f2781623dA91451bcBa84db01273Ee02', 18, 'STK', 'STK Finance', 'https://stk.org'),
  rw: new Token(MAINNET, '0x62263d6bd631B22C8153DE2a9CA88CB58345bc61', 18, 'RW', 'RW Finance', 'https://rw.org'),
  rwd: new Token(MAINNET, '0x8026d5b57B9697A7338c296ff276Dd007Fbf7Cfb', 18, 'RWD', 'Reward Token', 'https://rwd.org'),
} as const)

export const testnetTokens = defineTokens({
  astariant: new Token(
    TESTNET,
    '0xF22D2FEB149Fbf61915774a5bD2897c2157337e1',
    0,
    'ASTARIANT',
    'Astarians Plus Test',
    'https://astariansplus.com',
  ),
} as const)

const tokens = () => {
  const chainId = CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens
}

export default unserializedTokens
