import { MenuItemsType, EarnFillIcon, EarnIcon, CurrencyIcon, TuneIcon, InfoIcon } from '@kaiso/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  {
    label: t('MultiSender'),
    href: '/multisender',
    icon: CurrencyIcon,
    fillIcon: EarnFillIcon,
    items: [
      {
        label: t('MultiSender'),
        href: '/multisender',
      },
      {
        label: t('Revoke Approval'),
        href: '/revoke',
      },
    ],
  },
  {
    label: t('FAQ'),
    href: '/faq',
    icon: InfoIcon,
    fillIcon: InfoIcon,
    items: [
      {
        label: t('FAQs'),
        href: '/faq',
      },
    ],
  },
]

export default config
