import poolsConfig from 'config/constants/pools'
import sousChefNftABI from 'config/abi/sousChefNftV3.json'
import erc721EnumerableABI from 'config/abi/erc721Enumerable.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'

export const fetchPoolsAllowance = async (account) => {
  const calls = poolsConfig.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'isApprovedForAll',
    params: [account, getAddress(pool.contractAddress)],
  }))

  const allowances = await multicall(erc721EnumerableABI, calls)
  return poolsConfig.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: allowances[index][0] === true ? 1 : 0 }),
    {},
  )
}

export const fetchUserBalances = async (account) => {
  const calls = poolsConfig.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'balanceOf',
    params: [account],
  }))

  const tokenBalancesRaw = await multicall(erc721EnumerableABI, calls)
  const tokenBalances = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON(),
    }),
    {},
  )

  return { ...tokenBalances }
}

export const fetchUserStakeBalances = async (account) => {
  const calls = poolsConfig.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(sousChefNftABI, calls)
  const stakedBalances = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  const unstakedBalances = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(userInfo[index].amountUnstaked._hex).toJSON(),
    }),
    {},
  )

  return { stakedBalances, unstakedBalances }
}

export const fetchUserPendingRewards = async (account) => {
  const calls = poolsConfig.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }))
  const res = await multicall(sousChefNftABI, calls)
  const pendingRewards = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )

  return { ...pendingRewards }
}
function erc721Enumerable(erc721Enumerable: any, calls: { address: string; name: string; params: any[] }[]) {
  throw new Error('Function not implemented.')
}
